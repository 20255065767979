*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #E6E6FA 0%, #F5F5DC 100%);
    width: 100%;
    min-height: 100vh;
    padding: 15px;
}

.photo img {
    height: 150px;
    width: 150px;
    object-fit: cover;
    border-radius: 25px;
    border: 2px solid black;
}

.photo h1,
.photo h4 {
    color: rgb(243, 116, 116);
    margin: 0;
}

.photo hr {
    width: 300px;
    height: 6px;
    border-radius: 30px;
    background-color: rgb(14, 13, 13);
}

.name {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
}

.sub-container {
    margin-top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.name h2,
.name h3 {
    margin: 0;
}

.name h3 {
    font-style: italic;
    color: rgb(101, 100, 100);
}

.info-link {
    display: flex;
    list-style: none;
    gap: 30px;
    color: rgb(92, 39, 1);
    font-size: 20px;
    flex-wrap: wrap;
}

.info-link span,
.info-link a {
    cursor: pointer;
    width: 180px;
    height: 48px;
    border-radius: 35px;
    background-color: rgb(103, 86, 207);
    border: none;
    color: white;
    font-size: 20px;
    font-weight: 500;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.info-link span:hover,
.info-link a:hover {
    background-color: rgb(82, 69, 167);
}

.whatsapp-logo,
.call-logo,
.mail-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    height: 100%;
}

.whatsapp-logo img,
.call-logo img,
.mail-logo img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    background-color: white;
    border-radius: 50%;
}

.lower {
    text-align: center;
    margin-top: 30px;
    padding: 20px;
    background-color: #f0f0f0;
    width: 80%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: rgb(92, 39, 1);
    font-size: 18px;
}

.lower p, .lower a {
    margin: 10px 0;
    color: rgb(92, 39, 1);
    font-weight: 500;
    font-size: 18px;
    text-decoration: none;
}

.lower a {
    display: inline-block;
    transition: color 0.3s ease, text-decoration 0.3s ease;
}

.lower a:hover {
    color: rgb(82, 69, 167);
    text-decoration: underline;
}

/* Media Queries for Responsive Adjustments */

/* For screens up to 1080px wide */
@media (max-width: 1080px) {
    .info-link {
        display: flex;
        flex-wrap: wrap;
        gap: 20px; /* Adjust the gap between buttons */
        justify-content: center; /* Center buttons horizontally */
    }

    .info-link span,
    .info-link a {
        width: 180px; /* Adjust button width if needed */
        font-size: 20px; /* Adjust font size if needed */
    }
}

/* For screens up to 900px wide */
@media (max-width: 900px) {
    .info-link {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two buttons per row */
        gap: 15px; /* Space between buttons */
        justify-items: center; /* Center buttons horizontally */
    }

    .info-link span,
    .info-link a {
        width: 150px; 
        font-size: 18px; /* Adjust font size for smaller screens */
    }
}


@media (max-width: 768px) {
    .info-link {
        gap: 20px;
    }

    .info-link span,
    .info-link a {
        width: 150px;
        font-size: 18px;
    }

    .lower {
        width: 90%;
        font-size: 16px;
    }
}

@media (max-width: 500px) {
    .info-link {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two buttons per row */
        gap: 15px; /* Space between buttons */
        justify-items: center; /* Center buttons horizontally */
    }
    .photo h1{
        font-size: 29px;
    }
    .info-link span,
    .info-link a {
        width: 150px; /* Width of the buttons */
        font-size: 18px; /* Font size for buttons */
    }

    .lower {
        width: 95%; /* Adjust width for smaller screens */
        font-size: 14px; /* Font size for lower section */
    }
}

