.header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #E6E6FA 0%, #F5F5DC 100%);
    width:  100%;
    height: 80vh;
}
.payment-container{
    width: 460px;
    height: 50vh;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    margin: auto;
    margin-top: 80px;
}
.payment-container::before, 
.payment-container::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 5px;
    background-color: #E91E63;
}

.payment-container::before {
    top: -5px;
    left: -5px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 20px;
}

.payment-container::after {
    bottom: -5px;
    right: -5px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 20px;
}

h1{
    color: rgb(245, 67, 67);
}
.details{
   padding-top: 7px;
}

@media(max-width: 500px){
   .payment-container{
    width: 350px;
    height: 55vh;
   }
   .details{
    padding-top: 4px;
   }
   h2{
    font-size: 23px;
   }
}